<template>
    <div>
      <div class="columns is-centered">
        <div class="column is-full">
          <div v-if="isLoading">
            <Loading />
          </div>
          <div class="mt-4" :class="stubbed ? 'box block' : ''" v-else>
            <h1 class="title">Products</h1>
            <b-field>
              <b-switch
                  :value="sort === 'updatedAt'"
                  @input="changeSortBy"
                  passive-type="is-dark"
                  type="is-warning"
                >
                  {{ sort === 'updatedAt' ? "UPDATED" : "CREATED" }}
                </b-switch>
            </b-field>
            <h3 class="subtitle">Page: {{ page }}</h3>
            <p class="panel-tabs">
              <a :class="isAllActive" @click="getAll">All</a>
              <a :class="isSelfActive" @click="getSelf">Self</a>
              <a
                v-bind:class="isStatusActive('Open') ? 'is-active' : ''"
                @click="() => getProductsByStatus('Open')"
                >Open</a
              >
              <a
                v-bind:class="isStatusActive('Booked') ? 'is-active' : ''"
                @click="() => getProductsByStatus('Booked')"
                >
                Booked
              </a>
              <a
                v-bind:class="isStatusActive('To Be Booked') ? 'is-active' : ''"
                @click="() => getProductsByStatus('To Be Booked')"
                >
                To Be Booked
              </a>
              <a
                v-bind:class="isStatusActive('Mailed Provider') ? 'is-active' : ''"
                @click="() => getProductsByStatus('Mailed Provider')"
                >
                Mailed Provider
              </a>
              <a
                v-bind:class="isStatusActive('Passed') ? 'is-active' : ''"
                @click="() => getProductsByStatus('Passed')"
                >
                Passed
              </a>
              <a
                v-bind:class="isStatusActive('Retake Due') ? 'is-active' : ''"
                @click="() => getProductsByStatus('Retake Due')"
                >
                Retake Due
              </a>
              <a
                v-bind:class="isStatusActive('Dispute') ? 'is-active' : ''"
                @click="() => getProductsByStatus('Dispute')"
                >
                Dispute
              </a>
              <a
                v-bind:class="isStatusActive('Cancelled') ? 'is-active' : ''"
                @click="() => getProductsByStatus('Cancelled')"
                >
                Cancelled
              </a>
            </p>
            <table v-if="!isLoading" class="table is-fullwidth is-hoverable">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Product Name</th>
                  <th>Amount</th>
                  <th v-if="agent.type === 'Admin'">Process by</th>
                  <th v-if="!filter">Status</th>
                </tr>
              </thead>
              <tbody v-if="products.length">
                <tr
                  @click="navigateToOrder(product.orderId)"
                  v-for="product in netProducts"
                  :class="product.Order.Payment &&
                   product.Order.Payment.chargeId ? 'has-background-success-light' : ''"
                  :key="product.id"
                >
                  <td>
                    {{ new Date(product.createdAt).toDateString() }}
                  </td>
                  <td>{{ product.Order.User.title }}
                    {{ product.Order.User.firstName }} {{ product.Order.User.lastName }}
                  </td>
                  <td>{{ product.Order.User.email || "No email" }}</td>
                  <td>{{ product.name }}</td>
                  <td>
                      £{{ product.amount/100 }}
                  </td>
                  <td v-if="agent.type === 'Admin'">
                    <span v-if="product.Order.backendAgentId">
                      <b-tag type="is-light is-primary">
                        {{ getAgentById(product.Order.backendAgentId)}}
                      </b-tag>
                    </span>
                    <span v-else>
                      <b-tag type="is-light is-primary">
                        N/A
                     </b-tag>
                    </span>
                  </td>
                  <td v-if="!filter">
                    {{ product.status }}
                  </td>
                </tr>
              </tbody>
            </table>
            <div v-if="!stubbed" class="is-flex-direction-row">
              <b-button
                class="is-justify-content-flex-start"
                @click="decrementPage"
                :disabled="page === 1"
                >Prev</b-button
              >
              <b-button
                class="is-justify-content-flex-start"
                @click="
                  () => {
                    updateProductsViewPage(1);
                    queryProducts();
                  }
                "
                >1</b-button
              >
              <b-button
                class="is-justify-content-flex-start"
                @click="
                  () => {
                    updateProductsViewPage(5);
                    queryProducts();
                  }
                "
                >5</b-button
              >
              <b-button
                class="is-justify-content-flex-start"
                @click="
                  () => {
                    updateProductsViewPage(10);
                    queryProducts();
                  }
                "
                >10</b-button
              >
              <b-button
                class="is-justify-content-flex-start"
                @click="
                  () => {
                    updateProductsViewPage(20);
                    queryProducts();
                  }
                "
                >20</b-button
              >
              <b-button class="is-justify-content-flex-end" @click="incrementPage"
                >Next</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapState, mapActions } from 'vuex';
import Loading from '@/components/Loading.vue';

import { navigateToOrder, getAgentById } from '@/common';

export default {
  name: 'ProductsList',
  data: () => ({
    isLoading: true,
  }),
  props: {
    stubbed: Boolean,
  },
  async mounted() {
    try {
      await this.queryProducts();
    } catch (error) {
      console.error(error);
      this.$router.push({ name: 'Login' });
    } finally {
      this.isLoading = false;
    }
    return null;
  },
  components: {
    Loading,
  },
  methods: {
    ...mapActions([
      'queryProducts',
      'updateProductsViewPage',
      'setProductsViewFilter',
      'setProductsViewFilterStatus',
      'setProductsViewSort',
    ]),
    navigateToOrder,
    getAgentById,
    isStatusActive(status) {
      return this.filter === 'status' && this.status === status;
    },
    async changeSortBy(value) {
      this.isLoading = true;
      if (value) {
        this.setProductsViewSort('updatedAt');
      } else {
        this.setProductsViewSort('createdAt');
      }
      try {
        await this.queryProducts();
      } catch (error) {
        console.error(error);
        this.$router.push({ name: 'Home' });
      } finally {
        this.isLoading = false;
      }
    },
    async decrementPage() {
      this.updateProductsViewPage('DECREMENT');
      this.isLoading = true;
      try {
        await this.queryProducts();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async incrementPage() {
      this.updateProductsViewPage('INCREMENT');
      this.isLoading = true;
      try {
        await this.queryProducts();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getAll() {
      this.setProductsViewFilter(null);
      this.setProductsViewFilterStatus(null);

      this.isLoading = true;
      try {
        await this.queryProducts();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getSelf() {
      this.setProductsViewFilter('self');
      if (this.status) {
        this.setProductsViewFilterStatus(null);
      }

      this.isLoading = true;
      try {
        await this.queryProducts();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
    async getProductsByStatus(status) {
      if (this.filter !== 'status') {
        this.setProductsViewFilter('status');
      }
      this.setProductsViewFilterStatus(status);

      this.isLoading = true;
      try {
        await this.queryProducts();
      } catch (error) {
        this.$router.push({ name: 'Home' });
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapState({
      products: ({ productsView: { products } }) => products,
      page: ({ productsView: { page } }) => page,
      sort: ({ productsView: { sort } }) => sort,
      status: ({ productsView: { status } }) => status,
      filter: ({ productsView: { filter } }) => filter,
      agent: ({ agentData: { agent } }) => agent,
      allAgents: ({ agentData: { allAgents } }) => allAgents,
    }),
    netProducts() {
      if (this.stubbed) {
        return this.products && this.products.slice(0, 6);
      }
      return this.products;
    },
    isAllActive() {
      return this.filter !== 'self' && this.filter !== 'status'
        ? 'is-active'
        : '';
    },
    isSelfActive() {
      return this.filter === 'self' ? 'is-active' : '';
    },
  },
};
</script>
  <style scoped>
  table {
    cursor: pointer;
  }
  </style>
